// Success Message
function successMessage(message) {
    $(document.body).append("<div class='success-messsage'><div class='container'>"+ message +"</div></div>");
    setTimeout(function(){
        $(".success-messsage").fadeOut(200, function() {
            $(".success-messsage").remove();
        });
    }, 3000);
}

function infoMessage(message) {
    $(document.body).append("<div class='info-messsage'><div class='container'>"+ message +"<div class='info-message-close'><img src='assets/images/close-white.svg'></div></div></div>");
    $('.info-message-close').click(function() {
        $(".info-messsage").fadeOut(200, function() {
            $(".info-messsage").remove();
        });
    });
}

function warningMessage(message) {
    $(document.body).append("<div class='warning-messsage'><div class='container'>"+ message +"<div class='warning-message-close'><img src='assets/images/close-white.svg'></div></div></div>");
    $('.warning-message-close').click(function() {
        $(".warning-messsage").fadeOut(200, function() {
            $(".warning-messsage").remove();
        });
    });
}

function errorMessage(message) {
    $(document.body).append("<div class='error-messsage'><div class='container'>"+ message +"<div class='error-message-close'><img src='assets/images/close-white.svg'></div></div></div>");
    $('.error-message-close').click(function() {
        $(".error-messsage").fadeOut(200, function() {
            $(".error-messsage").remove();
        });
    });
}
